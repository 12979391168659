import React, {useState, useEffect} from "react";
//import { useLightbox } from 'simple-react-lightbox';
//import { SRLWrapper } from "simple-react-lightbox";

import AuthService from "./services/auth.service";
//import AuthVerify from "./common/auth-verify";
import authHeader from "./services/auth-header";
import EventBus from "./common/EventBus";
import {useNavigate,Link} from 'react-router-dom'
import axios from "axios";
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Checkbox from '@material-ui/core/Checkbox/Checkbox';
import Modal from 'react-bootstrap/Modal';
import NavigateNextRoundedIcon from '@mui/icons-material/NavigateNextRounded';
import NavigateBeforeRoundedIcon from '@mui/icons-material/NavigateBeforeRounded';


import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActionArea from '@mui/material/CardActionArea';

import Chip from '@mui/material/Chip';
import Linkk from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import MenuIcon from '@mui/icons-material/Menu';
import CircularProgress from '@mui/material/CircularProgress';

import FormLabel from '@mui/material/FormLabel';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';

import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
//import Modal from '@mui/material/Modal';

import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import Slide from '@mui/material/Slide';
import Button from '@mui/material/Button';

import TextField from '@mui/material/TextField';
//import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

import FormControl from '@mui/material/FormControl';
import Divider from '@mui/material/Divider';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@material-ui/core/ImageListItemBar';
import GridListTileBar from '@material-ui/core/GridListTileBar';

import ListSubheader from '@material-ui/core/ListSubheader';
import InfoIcon from '@mui/icons-material/Info';

import Alert from '@mui/material/Alert';
import PhotoOutlinedIcon from '@mui/icons-material/PhotoOutlined';


import { blueGrey } from '@mui/material/colors';
import { createTheme } from '@mui/material/styles';

//import { mod } from 'mathjs'


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));


const theme = createTheme({
  palette: {
    primary: {
      main: blueGrey[50],
    },
    secondary: {
      main: '#f44336',
    },
  },
});


const cardStyle = {
  height: "25vh",

border: 0.1,borderColor: 'grey.500'
};
/* width: {
        sx: 1.0, // 100%
        sm: 150,
        md: 250,
      },
*/
const cardStyleFullScreen = {


border: 0.1,borderColor: 'grey.500'
};

const JurorTests = () => {

  const [currentUser, setCurrentUser] = useState(undefined);
  const [isLoading,setIsLoading] = useState(false);
  ///const [loadingMsg,setLoadingMsg] = useState("Loading Photos");
  const [testCompetitors, setTestCompetitors] = useState([]);
  const [filtTestCompetitors, setFiltTestCompetitors] = useState([]);
  const [testNames,setTestNames]=useState([]);
  const [number_photos,setNumber_photos]=useState(0);

  const [selectedPhoto,setSelectedPhoto]=useState(null);

  const [compPhoto1,setCompPhoto1]=useState(null);
  const [compPhoto2,setCompPhoto2]=useState(null);
  const [compPhotoNumber,setCompPhotoNumber]=useState(0);


  const [selectedMirror,setSelectedMirror]=useState(null);
  const [selectedTestName,setSelectedTestName]=useState(null);
  const [selectedPhotoNumber,setSelectedPhotoNumber]=useState(0);

//Array to keep selected mirrors to Compare
const [checkedMirrors, setCheckedMirrors] = useState([]);
const [canCompare, setCanCompare] = useState(checkedMirrors.length===2);

//const [mirrorToCompare, setMirrorToCompare] = useState(null);
//const [mirrorToCompare2, setMirrorToCompare2] = useState(null);


const handleCheckMirrors = (event,mirrorToCompare) =>{
//console.log(mirrorToCompare,event.target.checked);
//console.log(checkedMirrors.filter(item => item === mirrorToCompare).length);
if (event.target.checked){ //add to compare
  if(checkedMirrors.length<2){
    setCheckedMirrors([...checkedMirrors, mirrorToCompare]);
  //  setCanCompare(checkedMirrors.length===2);
  }
  else
//  Alert
  console.log("Compare up to 2 mirrors");


}
else
{ //remove from Compare
  setCheckedMirrors(checkedMirrors.filter(item => item !== mirrorToCompare));
  //setCanCompare(checkedMirrors.length===2);
}
//console.log(checkedMirrors.length,checkedMirrors, canCompare);
//console.log(compPhoto1,compPhoto2,checkedMirrors.indexOf(mirrorToCompare));
}


  const [open, setOpen] = useState(false);
  const [compare, setCompare] = useState(false);

  const [filterselect, setFilterSelect] = React.useState("");

const [scrollY,setScrollY]=useState("0");
  //const myHeaders = authHeader();
  const navigate=useNavigate();



  document.title = "Juror Tests Page";

  const parseJwt = (token) => {
  try {
    return JSON.parse(atob(token.split('.')[1]));
  } catch (err) {
    alert("Authentication error. " + err);
    return null;
  }
}

  useEffect(() => {
    //document.body.style.position = "fixed";
    setIsLoading(true);
    const handleContextmenu = e => {
           e.preventDefault()
       };
    //console.log(checkedState);
    let data;
         //const user = AuthService.getCurrentUser();
     const myHeaders = authHeader();
     if (AuthService.getCurrentUser())
     {
       //console.log( AuthService.getCurrentUser());
       setCurrentUser(AuthService.getCurrentUser());
       if (AuthService.getCurrentUser()) {
         const decodedJwt = parseJwt(AuthService.getCurrentUser().accessToken);

         if (decodedJwt.exp * 1000 < Date.now()) {
           EventBus.dispatch("logout");
           navigate("/admin/JurorLogin");
         }
       }
     }
     else {
       EventBus.dispatch("logout");
       navigate("/admin/JurorLogin");
       //setCurrentUser("");
     };

    const API_URL1 = process.env.REACT_APP_BACKEND_URL+"/services/jurorTestCompetitors";
    const response = axios.get(API_URL1,{ headers:myHeaders}).then( response=>{
      //console.log(response);      ,{responseType: "blob",  headers:myHeaders})

      return response.data;}).then(data=>
        {
        //console.log(data);
        setTestCompetitors(data);
      //  setNumber_photos(data[0].photoFilePaths.length);
        //setFiltTestCompetitors(data);
        setTestNames([...new Set(data.map(q => q.testName))]);
        //console.log(testNames);
        setIsLoading(false);

    }).catch(err=>{
      EventBus.dispatch("logout");
      //alert("Cannot contact server." +err);
        navigate("/admin/JurorLogin");
        setIsLoading(false);
      //  setCurrentUser(undefined);
    })



  },[]);


  const filter = (e) => {
  const keyword = e.target.value;
  setFilterSelect(e.target.value);
  setCheckedMirrors([]);
  if (keyword !== 'All Tests') {
    const results = testCompetitors.filter((tcomp) => {
      return tcomp.testName.toLowerCase().startsWith(keyword.toLowerCase());
      // Use the toLowerCase() method to make it case-insensitive
    });
    //console.log(keyword,results);
    setFiltTestCompetitors(results);
    setNumber_photos(results[0].photoFilePaths.length);
  //  console.log(results[0].photoFilePaths.length);
  //  console.log(results);

    //console.log(number_photos);
  } else {
    setFiltTestCompetitors(testCompetitors);
    // If the text field is empty, show all users
  }
}


  const openPhoto = async (path,mirrorNumber,testName,i,scrollY) => {
  //let photourl;
  setIsLoading(true);
  let myHeaders = authHeader();
  const API_URL = process.env.REACT_APP_BACKEND_URL+"/services/file?fileName="+path;
//  console.log("***",i);
  setScrollY(scrollY);
  //console.log(scrollY);

  const d= await axios.get(API_URL
      ,{responseType: "blob",  headers:myHeaders})
      .then(response => {
        //console.log(response.data);
        setSelectedPhoto(URL.createObjectURL(response.data));
        setSelectedTestName(testName);
        setSelectedMirror(mirrorNumber);
        setSelectedPhotoNumber(i);

        //const photourl=URL.createObjectURL(response.data);
        //photourls.push({mirrorNumber:mirrorNumber,testId:testId,no:i,photo:URL.createObjectURL(response.data)});
      }).catch(err=>{
        EventBus.dispatch("logout");
        //alert("Cannot contact server." +err);
          navigate("/admin/JurorLogin");
          setIsLoading(false);
            setOpen(false);
        //  setCurrentUser(undefined);
      }).then(r =>{        //setOpen(true);
              //console.log(open);console.log(selectedPhoto);
              setIsLoading(false);
              setOpen(true);

            })
  };

  const comparePhotos = async (path1,path2,testName,i,scrollY) => {
  //let photourl;
  setIsLoading(true);
  let myHeaders = authHeader();
  const API_URL1 = process.env.REACT_APP_BACKEND_URL+"/services/file?fileName="+path1;
  const API_URL2 = process.env.REACT_APP_BACKEND_URL+"/services/file?fileName="+path2;

  //console.log(window.scrollY);
  setScrollY(scrollY);

  const [answer1,answer2]= [null,null];

  let endpoints = [
API_URL1,API_URL2
];

Promise.all(endpoints.map((endpoint) => axios.get(endpoint,{responseType: "blob",  headers:myHeaders})))
.then(
  axios.spread((...allData) => {
    //console.log({ allData });
    setCompPhoto1(URL.createObjectURL(allData[0].data));
     setCompPhoto2(URL.createObjectURL(allData[1].data));
     //console.log(i);
     setCompPhotoNumber(i);
     setIsLoading(false);
     setCompare(true);

  })
)
.catch(err=>{
        EventBus.dispatch("logout");
        alert("Cannot contact server." +err);
          navigate("/admin/JurorLogin");
          setIsLoading(false);
          setCompare(false);
    })
;

  };


  const handleClose = () => {
  setOpen(false);
  setCompare(false);
  //console.log(open);
  window.scrollTo(0, scrollY);
};

  return (
    <div onContextMenu={(event)=>event.preventDefault()}>
{open&&<div className="modal-dialog modal-dialog-centered">
{<Modal  show={open}   onHide={handleClose}>
  <Modal.Header>
    <Button disabled={isLoading}  startIcon={  <IconButton color="secondary" size="large">
        <NavigateBeforeRoundedIcon
        sx={{
 color: "white",
 backgroundColor: "blue",
 borderRadius: "50%"
}}
        fontSize="large" />
      </IconButton>}
     onClick = {(event)=>{event.preventDefault();
    //   console.log(filtTestCompetitors.filter(item => item.mirrorNumber === selectedMirror)[0].photoFilePaths[((selectedPhotoNumber-1)%number_photos + number_photos) %number_photos],((selectedPhotoNumber-1)%number_photos + number_photos) %number_photos,selectedPhotoNumber);
    //   console.log(number_photos);
        openPhoto(filtTestCompetitors.filter(item => item.mirrorNumber === selectedMirror)[0].photoFilePaths[((selectedPhotoNumber-1)%number_photos + number_photos) %number_photos],selectedMirror,selectedTestName,((selectedPhotoNumber-1)%number_photos + number_photos) %number_photos,scrollY);
  }}
     variant="outlined"
     type="button">
    </Button>
      <Button disabled={isLoading}  startIcon={  <IconButton color="secondary" size="large">
          <NavigateNextRoundedIcon   sx={{
    color: "white",
    backgroundColor: "blue",
    borderRadius: "50%"
  }} fontSize="large" />
        </IconButton>}
       onClick = {(event)=>{event.preventDefault();
      // console.log(filtTestCompetitors.filter(item => item.mirrorNumber === selectedMirror)[0].photoFilePaths[((selectedPhotoNumber+1)%number_photos + number_photos) %number_photos],selectedMirror,selectedTestName,((selectedPhotoNumber+1)%number_photos + number_photos) %number_photos,selectedPhotoNumber);
       openPhoto(filtTestCompetitors.filter(item => item.mirrorNumber === selectedMirror)[0].photoFilePaths[((selectedPhotoNumber+1)%number_photos + number_photos) %number_photos ],selectedMirror,selectedTestName,((selectedPhotoNumber+1)%number_photos + number_photos) %number_photos,scrollY );
     }}
       variant="outlined"
       type="button">
      </Button>
      <Button disabled={isLoading}  startIcon={  <IconButton color="secondary" size="large">
          <CloseIcon
          sx={{
         color: "white",
         backgroundColor: "blue",
         borderRadius: "50%"
         }}
          fontSize="large" />
        </IconButton>}
       onClick = {handleClose} variant="outlined"

       type="button" >
      </Button>

      <Typography color= "common.white" variant="button" sx={{ flexGrow: 1 }}>
      Mirror No : {selectedMirror}  Photo No: {selectedPhotoNumber+1}
         </Typography>
  </Modal.Header>
    <Modal.Body>


  {<img key={'p'}
  id = "photo"
     src={selectedPhoto}/>

   }

     {isLoading &&
     <Stack  style={{ alignItems: "center", display: "flex", justifyContent: "center"}}>
                   <CircularProgress  style={{ justifyContent: "center", position: "fixed", top: "60%" }} thickness={3} size={150}/>
     <span style={{ justifyContent: "center", position: "fixed", top: "65%" }}>Loading...</span>
     </Stack>
     }

</Modal.Body>

</Modal>}
</div>}

{compare&&<div className="modal-dialog modal-dialog-centered">
{<Modal  show={compare}   onHide={handleClose}>
  <Modal.Header>
    <Button disabled={isLoading}  startIcon={  <IconButton color="secondary" size="large">
        <NavigateBeforeRoundedIcon
        sx={{
 color: "white",
 backgroundColor: "blue",
 borderRadius: "50%"
}}
        fontSize="large" />
      </IconButton>}
     onClick = {(event)=>{event.preventDefault();
       //console.log(filtTestCompetitors.filter(item => item.mirrorNumber === selectedMirror)[0].photoFilePaths[((selectedPhotoNumber-1)%number_photos + number_photos) %number_photos],((selectedPhotoNumber-1)%number_photos + number_photos) %number_photos,selectedPhotoNumber);
        //openPhoto(filtTestCompetitors.filter(item => item.mirrorNumber === selectedMirror)[0].photoFilePaths[((selectedPhotoNumber-1)%number_photos + number_photos) %number_photos],selectedMirror,selectedTestName,((selectedPhotoNumber-1)%number_photos + number_photos) %number_photos,scrollY);
        comparePhotos(filtTestCompetitors.filter(item => item.mirrorNumber === checkedMirrors[0])[0].photoFilePaths[((compPhotoNumber-1)%number_photos + number_photos) %number_photos],filtTestCompetitors.filter(item => item.mirrorNumber === checkedMirrors[1])[0].photoFilePaths[((compPhotoNumber-1)%number_photos + number_photos) %number_photos],selectedTestName,((compPhotoNumber-1)%number_photos + number_photos) %number_photos,scrollY);
      //  console.log((compPhotoNumber-1)%number_photos + number_photos);

  }}
     variant="outlined"
     type="button">
    </Button>
      <Button disabled={isLoading}  startIcon={  <IconButton color="secondary" size="large">
          <NavigateNextRoundedIcon   sx={{
    color: "white",
    backgroundColor: "blue",
    borderRadius: "50%"
  }} fontSize="large" />
        </IconButton>}
       onClick = {(event)=>{event.preventDefault();
      // console.log(filtTestCompetitors.filter(item => item.mirrorNumber === checkedMirrors[0])[0].photoFilePaths[((compPhotoNumber+1)%number_photos + number_photos) %number_photos],filtTestCompetitors.filter(item => item.mirrorNumber === checkedMirrors[1])[0].photoFilePaths[((compPhotoNumber+1)%number_photos + number_photos) %number_photos],selectedTestName,((compPhotoNumber+1)%number_photos + number_photos) %number_photos,window.scrollY);
      // console.log(((compPhotoNumber+1)%number_photos + number_photos) %number_photos);
       //openPhoto(filtTestCompetitors.filter(item => item.mirrorNumber === selectedMirror)[0].photoFilePaths[((selectedPhotoNumber+1)%number_photos + number_photos) %number_photos ],selectedMirror,selectedTestName,((selectedPhotoNumber+1)%number_photos + number_photos) %number_photos,scrollY );
       comparePhotos(filtTestCompetitors.filter(item => item.mirrorNumber === checkedMirrors[0])[0].photoFilePaths[((compPhotoNumber+1)%number_photos + number_photos) %number_photos],filtTestCompetitors.filter(item => item.mirrorNumber === checkedMirrors[1])[0].photoFilePaths[((compPhotoNumber+1)%number_photos + number_photos) %number_photos],selectedTestName,((compPhotoNumber+1)%number_photos + number_photos) %number_photos,scrollY);


     }}
       variant="outlined"
       type="button">
      </Button>
      <Button disabled={isLoading}  startIcon={  <IconButton color="secondary" size="large">
          <CloseIcon
          sx={{
         color: "white",
         backgroundColor: "blue",
         borderRadius: "50%"
         }}
          fontSize="large" />
        </IconButton>}
       onClick = {handleClose} variant="outlined"

       type="button" >
      </Button>
  </Modal.Header>
    <Modal.Body>



    <ImageList   sx={{
        width:  '60vw',
        height: '90%',
        flex:1,
//objectFit: 'contain',

//flexWrap: 'nowrap',
        // Promote the list into its own layer in Chrome. This costs memory, but helps keeping high FPS.
      }}

    cols={2}>
 <ImageListItem key={'im1'}>
     <img className= "compareimages" key={'p1'}
  id = "photo"
     src={compPhoto1}/>
     <ImageListItemBar
     title=<span>Mirror: { checkedMirrors[0]}, Photo No: {compPhotoNumber+1}</span>

     />
</ImageListItem>
 <ImageListItem style={{   flex: '1' }} key={'im2'}>
       <img  className= "compareimages" key={'p2'}
     id = "photo1"
        src={compPhoto2}/>
        <ImageListItemBar
        title=<span>Mirror: { checkedMirrors[1]}, Photo No: {compPhotoNumber+1}</span>

        />
        </ImageListItem>
</ImageList>


        {isLoading &&
        <Stack  style={{ alignItems: "center", display: "flex", justifyContent: "center"}}>
                      <CircularProgress  style={{ justifyContent: "center", position: "fixed", top: "60%" }} thickness={4} size={200}/>
        <span style={{ justifyContent: "center", position: "fixed", top: "50%" }}>Loading...</span>
        </Stack>
        }

</Modal.Body>

</Modal>}
</div>}

{  <Box   sx={{

      top:'1%',
      left:'1%',
      right:'1%',
      bottom:'1%',
      display:'block',
      height: '97%',
      width: '97%',


      }}    onContextMenu={(event)=>event.preventDefault()} >

     {currentUser && (

       <AppBar theme={theme}
      position="static">
             <Toolbar>
               <IconButton
                 size="small"
                 variant="dense"
                 edge="start"
                 color="inherit"
                 aria-label="menu"
                 sx={{ mr: 2 }}
               >
                 <MenuIcon />
               </IconButton>
               <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
     Juror Test Assessment Page
               </Typography>
               <Button disabled={isLoading} color="inherit">      <Link to={"/admin/JurorLogin"} className="nav-link">
                       {"Logged in as "+currentUser.username}
                     </Link></Button>
             </Toolbar>
           </AppBar>

     )}

{!isLoading&&<Box>
  <Alert variant="outlined" severity="info">Choose mirrors (maximum 2) to compare photos</Alert>

  <Box
   display="flex"
  alignItems="center"
   direction="row" spacing={3}>
    <FormControl sx={{ padding: 2}} >

      <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
      Please select the test to see the competitors: </Typography>
      <RadioGroup  key={"rdg"+filterselect}
        aria-labelledby="demo-controlled-radio-buttons-group"
        name="controlled-radio-buttons-group"
        value={filterselect}
        onChange={filter}
      >
      {!isLoading&& (testNames.length>0)&&testNames.map((item,i) => {
      return (   <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}><FormControlLabel key={"mi_"+i} control={<Radio />} value={item}  label={item} /></Typography>);
      })}
      </RadioGroup>
    </FormControl>
</Box>
{filtTestCompetitors&&filtTestCompetitors.map((testcomp,i) => {
  return(

<Grid  container spacing={2} columns={5}>
<Item xs={2}>
<Typography variant="h8" component="div">
Mirror Number: {testcomp.mirrorNumber}</Typography>
<label  key={testcomp}   htmlFor={`custom-checkbox-${i}`}>
  <Checkbox color="default"
    id={`custom-checkbox-${i}`}
    checked={checkedMirrors.filter(item => item === testcomp.mirrorNumber).length>0}
    onChange={(event)=>{handleCheckMirrors(event,testcomp.mirrorNumber,i);
//console.log(checkedMirrors,checkedMirrors.indexOf(testcomp.mirrorNumber),i,testcomp.mirrorNumber);
    }}


  />Compare  </label>
</Item>
{testcomp&&testcomp.thumbnails.map((thumb,i)=>{
  return(
    <Item>
    <Typography variant="h8" component="div">
    Photo No {i+1}   </Typography>
    <img
      width="200"
      src={`data:image/jpeg;base64,${thumb}`}
      alt="Photo"
      onClick={()=>{ /*console.log(filtTestCompetitors,testcomp.photoFilePaths[i],testcomp.mirrorNumber,testcomp.testName,i);*/
        //console.log(testcomp.photoFilePaths[i],testcomp.mirrorNumber,((i)%number_photos + number_photos) %number_photos,i);
        openPhoto(testcomp.photoFilePaths[i],testcomp.mirrorNumber,testcomp.testName,i,window.scrollY);}}
    />
</Item>

  )
})
}
{((checkedMirrors.indexOf(testcomp.mirrorNumber,0)>-1))&&(checkedMirrors.length===2 )&&
  <Item>
<Typography variant="h8" component="div">
</Typography>
<label  key={testcomp}   htmlFor={`codebutton-${i}`}>
<Button disabled={!(!isLoading && checkedMirrors.length===2 && checkedMirrors.indexOf(testcomp.mirrorNumber)>-1)} id={`codebutton-${i}`} color="primary" variant="contained"
onClick={(e)=>{//console.log(compPhoto1,compPhoto2);

  //console.log(filtTestCompetitors.filter(item => item.mirrorNumber === checkedMirrors[0])[0].photoFilePaths[0]);
  //  console.log(filtTestCompetitors.filter(item => item.mirrorNumber === checkedMirrors[1])[0].photoFilePaths[0]);
  //  console.log( checkedMirrors[0])[0].photoFilePaths[0]);
    comparePhotos(filtTestCompetitors.filter(item => item.mirrorNumber === checkedMirrors[0])[0].photoFilePaths[0],filtTestCompetitors.filter(item => item.mirrorNumber === checkedMirrors[1])[0].photoFilePaths[0],testcomp.testName,0,window.scrollY);
}}
>
Compare </Button>  </label>
</Item>
}



</Grid>


);
})}



</Box>}
{isLoading &&
<Stack  style={{ alignItems: "center", display: "flex", justifyContent: "center"}}>
              <CircularProgress  style={{ justifyContent: "center", position: "fixed", top: "60%" }} thickness={1} size={150}/>
<span style={{ justifyContent: "center", position: "fixed", top: "65%" }}>Loading...</span>
</Stack>
}

</Box>}
</div>
);
}

export default JurorTests;
