import React from "react";


const Navbar = () => {

return (
    <nav className="navbar">
      <h1>  </h1>
         <a  href="https://omchairworld.com/" target="_blank" rel="noopener noreferrer">
          <img src={process.env.PUBLIC_URL + "/HW_logo.png"}  alt="logo" width= "100%" height="120" />
          </a>

      </nav>
);

}


export default Navbar;
