import * as React from 'react';
import { DataGrid, GridEditCellPropsParams } from '@mui/x-data-grid';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

function BooleanEditor(props: GridEditCellPropsParams) {
 const { id, value, api, field } = props;

 const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
 api.setEditCellValue({ id, field, value: event.target.value }, event);
 api.commitCellChange({ id, field });
 api.setCellMode(id, field, 'view');
 };

 return (
 <Select
 value={value || ''}
 onChange={handleChange}
 autoFocus
 onBlur={handleChange}
 >
 <MenuItem value={true}>true</MenuItem>
 <MenuItem value={false}>false</MenuItem>
 </Select>
 );
}

export default function EnrollmentGrid({ jurors, tests, enrollments, setEnrollments }) {
 const cellValues = React.useMemo(() => {
 return jurors.map((juror) => {
 const row = { id: juror.id, name: juror.name };
 tests.forEach((test) => {
 const enrollment = enrollments.find(
 (enrollment) =>
  enrollment.jurorId === juror.id &&
  enrollment.testId === Number(test.id)
 );
 row[String(test.id)] = enrollment ? enrollment.enrolled.toString() : 'false';
 });
 return row;
 });
 }, [jurors, tests, enrollments]);

 const columns = tests.map(test => ({
   field: test.id.toString(),
   headerName: test.name,
   width: 150,
   editable: true,
   renderEditCell: (params) => <BooleanEditor {...params} />,
 }));

 // Add the name column as the first column
 columns.unshift({ field: 'name', headerName: 'Name', width: 150, editable: false });
 const handleCellEditCommit = React.useCallback((params) => {
  const { id, field, value } = params;
  const isEnrolled = value.toString() === 'true';

  if (isEnrolled) {
    // If the value is true, add the pair to enrollments if it doesn't exist
    const enrollmentExists = enrollments.some((enrollment) =>
      enrollment.jurorId === id && enrollment.testId === Number(field)
    );

    if (!enrollmentExists) {
      setEnrollments((prevEnrollments) => [
        ...prevEnrollments,
        { jurorId: id, testId: Number(field), enrolled: isEnrolled },
      ]);
    }
  } else {
    // If the value is false, remove the pair from enrollments if it exists
    setEnrollments((prevEnrollments) =>
      prevEnrollments.filter((enrollment) =>
        !(enrollment.jurorId === id && enrollment.testId === Number(field))
      )
    );
  }
 }, [enrollments, setEnrollments]);


 const handlePreviewEnrollments = () => {
   console.log('Current enrollments:', enrollments);
   // Additional logic to preview enrollments can be added here
 };

 return (
   <div style={{ height: '100%', width: '100%' }}>
 <DataGrid
 key={enrollments.length}
 rows={cellValues}
 columns={columns}

 pageSize={100}
 disableSelectionOnClick
 paginationMode="client"
 onCellEditCommit={handleCellEditCommit}

 />

 </div>
 );
}
